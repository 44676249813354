import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getSettings, updateSettings } from "../../../store/settings/actions";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";

const Setting = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [settingData, setSettingData] = useState();
  const { settings, loading } = useSelector((state) => state.Setting);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [formData, setFormData] = useState({
    wordsPerToken: "",
    imageTokenRate: "",
    allowCustomPricing: "",
    minTokenPrice: "",
    maxTokenPrice: "",
    tokenRevenueShare: "",
    tags: [""],
    personalities: [""],
    hobbies: [""],
    newTag: "",
  });
  const fetchData = async () => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          setSettingData(res);
        }
      };
      dispatch(getSettings({ callback }));
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (settingData) {
      setFormData({
        wordsPerToken: settingData?.tokenSetting?.wordsPerToken,
        imageTokenRate: settingData?.tokenSetting?.imageTokenRate,
        allowCustomPricing: settingData?.tokenSetting?.allowCustomPricing,
        minTokenPrice: settingData?.tokenSetting?.minTokenPrice,
        maxTokenPrice: settingData?.tokenSetting?.maxTokenPrice,
        tokenRevenueShare: settingData?.tokenSetting?.tokenRevenueShare,
        tags: settingData?.tags?.map((item) => item) || [],
      });
    }
  }, [settingData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  const addTag = () => {
    const trimmedTag = formData.newTag?.trim();
    if (!trimmedTag) {
      toast.error("Tag cannot be empty!");
      return;
    }
    if (formData.tags.includes(trimmedTag)) {
      toast.error("Tag already exists!");
      return;
    }
    setFormData({
      ...formData,
      tags: [...formData.tags, trimmedTag],
      newTag: "",
    });
  };
  const removeTag = (index) => {
    const updatedTags = formData.tags.filter((_, i) => i !== index);
    setFormData({ ...formData, tags: updatedTags });
  };

  const handleSubmit_ = (info) => {
    try {
      let query = {
        tokenSetting: {
          wordsPerToken: info?.wordsPerToken,
          imageTokenRate: info?.imageTokenRate,
          allowCustomPricing: info?.allowCustomPricing,
          minTokenPrice: info?.minTokenPrice,
          maxTokenPrice: info?.maxTokenPrice,
          tokenRevenueShare: info?.tokenRevenueShare,
        },
        tags: info?.tags?.map((item) => item) || [],
      };
      const callBack = (err, res) => {
        if (err) {
          console.error(err, "error message");
        } else {
          console.log(res);
          toast.success("Plan Updated Successfully");
        }
      };
      dispatch(updateSettings({ data: query, callback: callBack }));
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  return (
    <>
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <section className="addForm position-relative py-3">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="cardCstm p-3 rounded bg-white">
                <Form
                  className="mx-auto"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit_(formData);
                  }}
                >
                  <div className="py-3">
                    <div className="topHead pb-2">
                      <h5 className="m-0 fw-sbold" style={{ color: "#2E3A59" }}>
                        Basic Settings
                      </h5>
                    </div>
                    <div className="mx-auto" style={{ maxWidth: 777 }}>
                      <Row className="justify-content-center">
                        <Col md="6" className="my-2 ">
                          <label
                            htmlFor="words"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Words per day
                          </label>
                          <input
                            type=""
                            className="form-control"
                            name="wordsPerToken"
                            value={formData.wordsPerToken}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md="6" className="my-2 ">
                          <label
                            htmlFor="words"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            imageTokenRate
                          </label>
                          <input
                            type=""
                            className="form-control"
                            name="imageTokenRate"
                            value={formData.imageTokenRate}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md="6" className="my-2 ">
                          <label
                            htmlFor="words"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            minTokenPrice
                          </label>
                          <input
                            type=""
                            className="form-control"
                            name="minTokenPrice"
                            value={formData.minTokenPrice}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md="6" className="my-2 ">
                          <label
                            htmlFor="words"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            maxTokenPrice
                          </label>
                          <input
                            type=""
                            className="form-control"
                            name="maxTokenPrice"
                            value={formData.maxTokenPrice}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md="6" className="my-2 ">
                          <label
                            htmlFor="words"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            tokenRevenueShare
                          </label>
                          <input
                            type=""
                            className="form-control"
                            name="tokenRevenueShare"
                            value={formData.tokenRevenueShare}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md="6" className="my-2 ">
                          <label
                            htmlFor="wordsInterval"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Allow Custom Pricing
                          </label>
                          <select
                            className="form-control"
                            name="allowCustomPricing"
                            value={formData.allowCustomPricing}
                            onChange={handleChange}
                          >
                            <option value="true">True</option>
                            <option value="false">False</option>
                          </select>
                        </Col>
                        <Col md="12" className="my-2">
                          <label
                            htmlFor="tags"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Add Tags
                          </label>
                          <div className="d-flex align-items-center mb-2">
                            <input
                              type="text"
                              className="form-control me-2"
                              name="newTag"
                              value={formData.newTag}
                              onChange={handleChange}
                            />
                            <Button className="btn-sm" onClick={addTag}>
                              Save
                            </Button>
                          </div>
                          <div className="mt-4">
                            <label
                              htmlFor=""
                              className="form-label font-medium"
                            >
                              Tags
                            </label>
                            <ul className="list-unstyled pl-0 mb-0 d-flex align-items-center gap-2 flex-wrap">
                              {formData?.tags?.map((tag, index) => (
                                <li
                                  key={index}
                                  className="d-flex align-items-center gap-2"
                                >
                                  <div className="d-flex align-items-center bg-light py-2 px-4 rounded-pill">
                                    {tag || "New Tag"}
                                    <Button
                                      variant="danger"
                                      className="btn-sm ms-2"
                                      onClick={() => removeTag(index)}
                                      disabled={formData.tags.length === 1}
                                    >
                                      X
                                    </Button>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="py-3">
                    <div className="mx-auto" style={{ maxWidth: 777 }}>
                      <Row>
                        <Col md="5" className="my-2 ">
                          <Button
                            className="d-flex align-items-center justify-content-center commonBtn w-100 fw-sbold"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Setting;
