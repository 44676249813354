import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import FeatureCard from "./components/FeatureCard";
import AreaChart from "../../../components/Graph/AreaChart";
const Dashboard = () => {
  let filterby = "Week";
  return (
    <>
      <section className="position-relative">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <FeatureCard />
            </Col>
            <Col md="12" className="my-2">
              <div className="cardCstm p-3 h-100 rounded border bg-white">
                <div className="d-flex justify-content-between px-2">
                  <div className="cardHead m-0 pb-2">
                    <h6 className="m-0 fw-bolder">Last 7 days Revenue</h6>
                  </div>
                  <div className="cardHead m-0 pb-2">
                    <div className="col-span-12">
                      <ul className="list-unstyled pl-0 mb-0 d-flex align-items-center gap-10  rounded ">
                        <li className="px-2">
                          <button
                            // onClick={() => HandleYearGraph("Week")}
                            className="border-0 p-0 bg-transparent fw-medium  d-flex align-content-center"
                          >
                            {filterby === "Week" ? (
                              <span className="icn me-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="17"
                                  height="17"
                                  viewBox="0 0 17 17"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_33_4871)">
                                    <path
                                      d="M16.1866 4.86446C15.4927 3.39356 14.3937 2.1584 13.0091 1.2918C11.0833 0.0898467 8.80227 -0.291989 6.59094 0.219339C4.37961 0.727347 2.49699 2.06875 1.29504 3.99453C0.0897661 5.92032 -0.29207 8.19805 0.219258 10.4127C0.730586 12.624 2.07199 14.5066 3.99445 15.7086C5.34914 16.5553 6.90305 17.0035 8.49348 17.0035H8.59641C10.1802 16.9836 11.7208 16.5254 13.0556 15.682C13.4441 15.4363 13.557 14.925 13.3113 14.5365C13.0655 14.148 12.5542 14.0352 12.1657 14.2809C11.0933 14.9615 9.85149 15.3301 8.57648 15.3467C7.26828 15.3633 5.98996 15.0014 4.87434 14.3074C3.32375 13.3379 2.24465 11.8238 1.83625 10.0441C1.42785 8.26446 1.73332 6.43164 2.70285 4.88106C4.70168 1.6836 8.93176 0.707425 12.1292 2.70625C13.2448 3.40352 14.128 4.39629 14.6859 5.57832C15.2304 6.73047 15.4429 8.00879 15.3001 9.27051C15.2503 9.72539 15.5757 10.1371 16.0339 10.1869C16.4888 10.2367 16.9005 9.91133 16.9503 9.45313C17.1263 7.88262 16.8607 6.29551 16.1866 4.86446Z"
                                      fill="url(#paint0_linear_33_4871)"
                                    />
                                    <path
                                      d="M11.2458 5.61753L7.2415 9.62182L5.75732 8.13764C5.43193 7.81225 4.90732 7.81225 4.58193 8.13764C4.25654 8.46303 4.25654 8.98764 4.58193 9.31303L6.65381 11.3849C6.8165 11.5476 7.029 11.6273 7.2415 11.6273C7.454 11.6273 7.6665 11.5476 7.8292 11.3849L12.4179 6.79292C12.7433 6.46753 12.7433 5.94292 12.4179 5.61753C12.0925 5.29546 11.5679 5.29546 11.2458 5.61753Z"
                                      fill="url(#paint1_linear_33_4871)"
                                    />
                                  </g>
                                  <defs>
                                    <linearGradient
                                      id="paint0_linear_33_4871"
                                      x1="8.50061"
                                      y1="-0.00195313"
                                      x2="8.50061"
                                      y2="17.0035"
                                      gradientUnits="userSpaceOnUse"
                                    >
                                      <stop stop-color="#6D53A3" />
                                      <stop offset="1" stop-color="#76428B" />
                                    </linearGradient>
                                    <linearGradient
                                      id="paint1_linear_33_4871"
                                      x1="8.4999"
                                      y1="5.37598"
                                      x2="8.4999"
                                      y2="11.6273"
                                      gradientUnits="userSpaceOnUse"
                                    >
                                      <stop stop-color="#6D53A3" />
                                      <stop offset="1" stop-color="#76428B" />
                                    </linearGradient>
                                    <clipPath id="clip0_33_4871">
                                      <rect
                                        width="17"
                                        height="17"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </span>
                            ) : (
                              <span className="icn me-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="17"
                                  height="17"
                                  viewBox="0 0 17 17"
                                  fill="none"
                                >
                                  <path
                                    d="M16.1866 4.86446C15.4927 3.39356 14.3937 2.1584 13.0091 1.2918C11.0833 0.0898467 8.80227 -0.291989 6.59094 0.219339C4.37961 0.727347 2.49699 2.06875 1.29504 3.99453C0.0897661 5.92032 -0.29207 8.19805 0.219258 10.4127C0.730586 12.624 2.07199 14.5066 3.99445 15.7086C5.34914 16.5553 6.90305 17.0035 8.49348 17.0035H8.59641C10.1802 16.9836 11.7208 16.5254 13.0556 15.682C13.4441 15.4363 13.557 14.925 13.3113 14.5365C13.0655 14.148 12.5542 14.0352 12.1657 14.2809C11.0933 14.9615 9.85149 15.3301 8.57648 15.3467C7.26828 15.3633 5.98996 15.0014 4.87434 14.3074C3.32375 13.3379 2.24465 11.8238 1.83625 10.0441C1.42785 8.26446 1.73332 6.43164 2.70285 4.88106C4.70168 1.6836 8.93176 0.707425 12.1292 2.70625C13.2448 3.40352 14.128 4.39629 14.6859 5.57832C15.2304 6.73047 15.4429 8.00879 15.3001 9.27051C15.2503 9.72539 15.5757 10.1371 16.0339 10.1869C16.4888 10.2367 16.9005 9.91133 16.9503 9.45313C17.1263 7.88262 16.8607 6.29551 16.1866 4.86446Z"
                                    fill="#D7D7D7"
                                  />
                                </svg>
                              </span>
                            )}
                            Weekly
                          </button>
                        </li>
                        <li className="px-2">
                          <button
                            // onClick={() => HandleYearGraph("Month")}
                            className="border-0 p-0 bg-transparent font-medium text-dark flex items-center"
                          >
                            {filterby === "Month" ? (
                              <span className="icn me-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="17"
                                  height="17"
                                  viewBox="0 0 17 17"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_33_4871)">
                                    <path
                                      d="M16.1866 4.86446C15.4927 3.39356 14.3937 2.1584 13.0091 1.2918C11.0833 0.0898467 8.80227 -0.291989 6.59094 0.219339C4.37961 0.727347 2.49699 2.06875 1.29504 3.99453C0.0897661 5.92032 -0.29207 8.19805 0.219258 10.4127C0.730586 12.624 2.07199 14.5066 3.99445 15.7086C5.34914 16.5553 6.90305 17.0035 8.49348 17.0035H8.59641C10.1802 16.9836 11.7208 16.5254 13.0556 15.682C13.4441 15.4363 13.557 14.925 13.3113 14.5365C13.0655 14.148 12.5542 14.0352 12.1657 14.2809C11.0933 14.9615 9.85149 15.3301 8.57648 15.3467C7.26828 15.3633 5.98996 15.0014 4.87434 14.3074C3.32375 13.3379 2.24465 11.8238 1.83625 10.0441C1.42785 8.26446 1.73332 6.43164 2.70285 4.88106C4.70168 1.6836 8.93176 0.707425 12.1292 2.70625C13.2448 3.40352 14.128 4.39629 14.6859 5.57832C15.2304 6.73047 15.4429 8.00879 15.3001 9.27051C15.2503 9.72539 15.5757 10.1371 16.0339 10.1869C16.4888 10.2367 16.9005 9.91133 16.9503 9.45313C17.1263 7.88262 16.8607 6.29551 16.1866 4.86446Z"
                                      fill="url(#paint0_linear_33_4871)"
                                    />
                                    <path
                                      d="M11.2458 5.61753L7.2415 9.62182L5.75732 8.13764C5.43193 7.81225 4.90732 7.81225 4.58193 8.13764C4.25654 8.46303 4.25654 8.98764 4.58193 9.31303L6.65381 11.3849C6.8165 11.5476 7.029 11.6273 7.2415 11.6273C7.454 11.6273 7.6665 11.5476 7.8292 11.3849L12.4179 6.79292C12.7433 6.46753 12.7433 5.94292 12.4179 5.61753C12.0925 5.29546 11.5679 5.29546 11.2458 5.61753Z"
                                      fill="url(#paint1_linear_33_4871)"
                                    />
                                  </g>
                                  <defs>
                                    <linearGradient
                                      id="paint0_linear_33_4871"
                                      x1="8.50061"
                                      y1="-0.00195313"
                                      x2="8.50061"
                                      y2="17.0035"
                                      gradientUnits="userSpaceOnUse"
                                    >
                                      <stop stop-color="#6D53A3" />
                                      <stop offset="1" stop-color="#76428B" />
                                    </linearGradient>
                                    <linearGradient
                                      id="paint1_linear_33_4871"
                                      x1="8.4999"
                                      y1="5.37598"
                                      x2="8.4999"
                                      y2="11.6273"
                                      gradientUnits="userSpaceOnUse"
                                    >
                                      <stop stop-color="#6D53A3" />
                                      <stop offset="1" stop-color="#76428B" />
                                    </linearGradient>
                                    <clipPath id="clip0_33_4871">
                                      <rect
                                        width="17"
                                        height="17"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </span>
                            ) : (
                              <span className="icn me-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="17"
                                  height="17"
                                  viewBox="0 0 17 17"
                                  fill="none"
                                >
                                  <path
                                    d="M16.1866 4.86446C15.4927 3.39356 14.3937 2.1584 13.0091 1.2918C11.0833 0.0898467 8.80227 -0.291989 6.59094 0.219339C4.37961 0.727347 2.49699 2.06875 1.29504 3.99453C0.0897661 5.92032 -0.29207 8.19805 0.219258 10.4127C0.730586 12.624 2.07199 14.5066 3.99445 15.7086C5.34914 16.5553 6.90305 17.0035 8.49348 17.0035H8.59641C10.1802 16.9836 11.7208 16.5254 13.0556 15.682C13.4441 15.4363 13.557 14.925 13.3113 14.5365C13.0655 14.148 12.5542 14.0352 12.1657 14.2809C11.0933 14.9615 9.85149 15.3301 8.57648 15.3467C7.26828 15.3633 5.98996 15.0014 4.87434 14.3074C3.32375 13.3379 2.24465 11.8238 1.83625 10.0441C1.42785 8.26446 1.73332 6.43164 2.70285 4.88106C4.70168 1.6836 8.93176 0.707425 12.1292 2.70625C13.2448 3.40352 14.128 4.39629 14.6859 5.57832C15.2304 6.73047 15.4429 8.00879 15.3001 9.27051C15.2503 9.72539 15.5757 10.1371 16.0339 10.1869C16.4888 10.2367 16.9005 9.91133 16.9503 9.45313C17.1263 7.88262 16.8607 6.29551 16.1866 4.86446Z"
                                    fill="#D7D7D7"
                                  />
                                </svg>
                              </span>
                            )}
                            Monthly
                          </button>
                        </li>
                        <li className="px-2">
                          <button
                            // onClick={() => HandleYearGraph("Year")}
                            className="border-0 p-0 bg-transparent font-medium text-dark flex items-center"
                          >
                            {filterby === "Year" ? (
                              <span className="icn me-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="17"
                                  height="17"
                                  viewBox="0 0 17 17"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_33_4871)">
                                    <path
                                      d="M16.1866 4.86446C15.4927 3.39356 14.3937 2.1584 13.0091 1.2918C11.0833 0.0898467 8.80227 -0.291989 6.59094 0.219339C4.37961 0.727347 2.49699 2.06875 1.29504 3.99453C0.0897661 5.92032 -0.29207 8.19805 0.219258 10.4127C0.730586 12.624 2.07199 14.5066 3.99445 15.7086C5.34914 16.5553 6.90305 17.0035 8.49348 17.0035H8.59641C10.1802 16.9836 11.7208 16.5254 13.0556 15.682C13.4441 15.4363 13.557 14.925 13.3113 14.5365C13.0655 14.148 12.5542 14.0352 12.1657 14.2809C11.0933 14.9615 9.85149 15.3301 8.57648 15.3467C7.26828 15.3633 5.98996 15.0014 4.87434 14.3074C3.32375 13.3379 2.24465 11.8238 1.83625 10.0441C1.42785 8.26446 1.73332 6.43164 2.70285 4.88106C4.70168 1.6836 8.93176 0.707425 12.1292 2.70625C13.2448 3.40352 14.128 4.39629 14.6859 5.57832C15.2304 6.73047 15.4429 8.00879 15.3001 9.27051C15.2503 9.72539 15.5757 10.1371 16.0339 10.1869C16.4888 10.2367 16.9005 9.91133 16.9503 9.45313C17.1263 7.88262 16.8607 6.29551 16.1866 4.86446Z"
                                      fill="url(#paint0_linear_33_4871)"
                                    />
                                    <path
                                      d="M11.2458 5.61753L7.2415 9.62182L5.75732 8.13764C5.43193 7.81225 4.90732 7.81225 4.58193 8.13764C4.25654 8.46303 4.25654 8.98764 4.58193 9.31303L6.65381 11.3849C6.8165 11.5476 7.029 11.6273 7.2415 11.6273C7.454 11.6273 7.6665 11.5476 7.8292 11.3849L12.4179 6.79292C12.7433 6.46753 12.7433 5.94292 12.4179 5.61753C12.0925 5.29546 11.5679 5.29546 11.2458 5.61753Z"
                                      fill="url(#paint1_linear_33_4871)"
                                    />
                                  </g>
                                  <defs>
                                    <linearGradient
                                      id="paint0_linear_33_4871"
                                      x1="8.50061"
                                      y1="-0.00195313"
                                      x2="8.50061"
                                      y2="17.0035"
                                      gradientUnits="userSpaceOnUse"
                                    >
                                      <stop stop-color="#6D53A3" />
                                      <stop offset="1" stop-color="#76428B" />
                                    </linearGradient>
                                    <linearGradient
                                      id="paint1_linear_33_4871"
                                      x1="8.4999"
                                      y1="5.37598"
                                      x2="8.4999"
                                      y2="11.6273"
                                      gradientUnits="userSpaceOnUse"
                                    >
                                      <stop stop-color="#6D53A3" />
                                      <stop offset="1" stop-color="#76428B" />
                                    </linearGradient>
                                    <clipPath id="clip0_33_4871">
                                      <rect
                                        width="17"
                                        height="17"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </span>
                            ) : (
                              <span className="icn me-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="17"
                                  height="17"
                                  viewBox="0 0 17 17"
                                  fill="none"
                                >
                                  <path
                                    d="M16.1866 4.86446C15.4927 3.39356 14.3937 2.1584 13.0091 1.2918C11.0833 0.0898467 8.80227 -0.291989 6.59094 0.219339C4.37961 0.727347 2.49699 2.06875 1.29504 3.99453C0.0897661 5.92032 -0.29207 8.19805 0.219258 10.4127C0.730586 12.624 2.07199 14.5066 3.99445 15.7086C5.34914 16.5553 6.90305 17.0035 8.49348 17.0035H8.59641C10.1802 16.9836 11.7208 16.5254 13.0556 15.682C13.4441 15.4363 13.557 14.925 13.3113 14.5365C13.0655 14.148 12.5542 14.0352 12.1657 14.2809C11.0933 14.9615 9.85149 15.3301 8.57648 15.3467C7.26828 15.3633 5.98996 15.0014 4.87434 14.3074C3.32375 13.3379 2.24465 11.8238 1.83625 10.0441C1.42785 8.26446 1.73332 6.43164 2.70285 4.88106C4.70168 1.6836 8.93176 0.707425 12.1292 2.70625C13.2448 3.40352 14.128 4.39629 14.6859 5.57832C15.2304 6.73047 15.4429 8.00879 15.3001 9.27051C15.2503 9.72539 15.5757 10.1371 16.0339 10.1869C16.4888 10.2367 16.9005 9.91133 16.9503 9.45313C17.1263 7.88262 16.8607 6.29551 16.1866 4.86446Z"
                                    fill="#D7D7D7"
                                  />
                                </svg>
                              </span>
                            )}
                            Yearly
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="cardBody">
                  <AreaChart />
                </div>
              </div>
            </Col>
            {/* <Col md="4" sm="6" className="my-2">
              <div className="cardCstm p-3 h-100 rounded border bg-white">
                <div className="cardHead m-0 pb-2">
                  <h6 className="m-0 fw-bolder">Attendance</h6>
                </div>
                <div className="cardBody">
                  <PieDonutChart />
                </div>
              </div>
            </Col> */}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Dashboard;
